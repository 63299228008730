import { ClipboardListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Motivationsschreiben für eine Initiativbewerbung erstellen ",
  desc: "Erstellen Sie ein professionelles Motivationsschreiben für eine Initiativbewerbung.",
  category: "Job",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/job/motivationletter_initiative",
  api: "/ai/job/motivationletter_initiative",
  apiCv: "/ai/upload-cv-motivationletter1",
  currentSchema: "motivationletter_initiative",

  fromColor: "blue-600",
  toColor: "yellow-500",

  output: {
    title: "Motivationsschreiben",
    desc: "Beispiel für ein Motivationsschreiben. Hinweis: Wir übernehmen keine Haftung für die Korrektheit, deswegen empfehlen wir den generierten Text ausführlich zu prüfen.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Bewerbungsschreiben Classic",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank!",
      // n: 1,
      prompts: [
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: Bosch GmbH, Siemens AG",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "Tennet",
        },
        {
          title: "Die Branche oder der Bereich des Unternehmens",
          attr: "companyDescriptoin",
          value: "",
          placeholder:
            "Schreib hier etwas über die Firma oder den Bereich für den du dich Bewerben möchtest. " +
            "",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 1000,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `Wir transportieren den Strom über unser schnell wachsendes Hochspannungsnetz über Land, unter der Erde, im Meer und auch über die Grenzen hinweg. Mit einer Netzverfügbarkeit von 99,99963 % zählen wir weltweit zu den Besten in unserem Metier.
Als Netzbetreiber sind wir für die Sicherheit der Stromversorgung und die Aufrechterhaltung des Netzgleichgewichtes verantwortlich. `,
        },

        {
          title: "Wie bist du auf die Firma aufmerksam geworden? (Optional)",
          attr: "sourceListing",
          value: "",
          placeholder: "Google Suche",
          label: "Beispiel: Stepstone, Xing",

          maxLength: 150,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "indeed.com",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "nameHiringManager",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },

        {
          title: "Dein aktuelle Status (Optional)",
          attr: "myStatus",
          value: "",
          placeholder:
            "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "Ich habe einen Masterabschluss mit 1 Jahr Berufserfahrung",
        },
        {
          title:
            "Die spezifische Rolle oder Abteilung, dich dich interessiert (Optional)",
          attr: "myInterest",
          value: "",
          placeholder:
            "Erzähle kurz darüber, welche Abteilung oder Postition dich interessiert?",
          label: "",
          type: "textarea",
          maxLength: 500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich interessiere mich für die Stelle als Entwicklungsingenieur in der R&D Abteilung. ",
        },
        {
          title: "Deine Ausbildung",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deinen Werdegang...",
          // label:
          //   "Examples: Adrian, adrian@example.com, Call Adrian on 041021031",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "MASTER Hochschule, Umwelttechnologie Amberg-Weiden",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung,`,
          label: "Optional",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: `CONCEPTER / VERTRIEBSINGENIEUR Random GmbH - Konzeptplanung im Rahmen von Ausschreibungsprojekten für Intralogistiksysteme - Analyse und Abstimmung der Kundenanforderungen für die
          Erstellung von Konzeptanforderungskatalogen - Durchführung von Analysen der Kundenprozesse und -daten zur Lösungserstellung
          - Erarbeitung von wirtschaftlichen und skalierbaren Konzepten im Rahmen einer Gesamtwirtschaftlichkeitsrechnung`,
        },

        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "MS Office, CAD, SolidWorks, Konstruktion, Programmierung ",
        },
        {
          title: "Auf welcher Sprache soll das Anschreiben verfasst werden?",
          attr: "language",
          value: "",
          //label: "DE: Deutsch, EN: Englisch",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Damen und Herren,

        mein Name ist [Name] und ich bin auf der Suche nach neuen beruflichen Herausforderungen, die meine Fähigkeiten und Qualifikationen nutzen und weiterentwickeln können. Derzeit arbeite ich als Concepter/Vertriebsingenieur bei Dematic GmbH und besitze einen Masterabschluss in Umwelttechnologie. 
       
        Das Unternehmen Tennet hat aufgrund meines Freundeskreises meine Aufmerksamkeit erlangt und ich bin sehr interessiert an den Möglichkeiten, die Ihnen als führendem Übertragungsnetzbetreiber in Europa bieten können. 
        
        Ich bin besonders fasziniert von der Projektplanung bei Tennet und glaube, dass ich durch meine Fähigkeiten in der Konzeptplanung und der Gesamtwirtschaftlichkeitsrechnung einen wertvollen Beitrag leisten könnte. Zudem besitze ich Kenntnisse in Microsoft Office, Solid Edge, Maestro und Aspen HCSCS sowie AutoADDie. 
        
        Ich bin von der Bedeutung des Energietransports sowie Ihrem Engagement für erneuerbare Energien beeindruckt und möchte gerne dazu beitragen, Ihre Ziele und Visionen weiter voranzutreiben. 
        
        Ich hoffe, auf eine Gelegenheit, meine umfangreichen Fähigkeiten und Erfahrungen in diesem innovativen Unternehmen zu nutzen und freue mich darauf, von Ihnen zu hören.
        
        Mit freundlichen Grüßen,
        
        [Dein Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
      // },
      // {
      //   title: "Motivation Letter Basic",
      //   desc: "Please fill the blanks...",
      //   // n: 1,
      //   prompts: [
      //     {
      //       title: "Job Title",
      //       attr: "title",
      //       value: "",
      //       placeholder: "Junior Accountant",
      //       label: "Examples: Accountant, Developer, Dentist, Receptionist",
      //       // type: "textarea",
      //       maxLength: 40,
      //       // max: 100,
      //       min: 10,
      //       required: true,
      //       error: "",
      //       example: "Junior Accountant",
      //     },
      //     {
      //       title: "Company",
      //       attr: "company",
      //       value: "",
      //       placeholder: "Company Ptd Ltd",
      //       label: "Examples: Tax Point Inc., Moshi Pty Ltd, Studium",
      //       // type: "textarea",
      //       maxLength: 40,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "Smith and Co",
      //     },
      //     {
      //       title: "Job Description",
      //       attr: "description",
      //       value: "",
      //       placeholder:
      //         "We are looking for a Software Developer to build and implement functional programs.",
      //       // label: "Examples: 40k, $20,000, $50 per hour",
      //       type: "textarea",
      //       maxLength: 1500,
      //       // max: 100,
      //       min: 3,
      //       required: true,
      //       error: "",
      //       example:
      //         "We are looking for a Software Developer to build and implement functional programs.",
      //     },
      //     {
      //       title: "Your Skills",
      //       attr: "skills",
      //       value: "",
      //       placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //       // label:
      //       //   "Examples: Adrian, adrian@example.com, Call Adrian on 041021031",
      //       type: "textarea",
      //       maxLength: 600,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //     },
      //   ],
      //   example: {
      //     output: `Dear Hiring Manager,

      //     I am writing to apply for the position of Senior Inhouse Consultant SAP FI at BestSecret Group. With my extensive experience in SAP FI consulting, solution design, and hands-on customizing, I am confident that I am the ideal candidate for this role.

      //     My experience includes working as an SAP Consultant for data migration at Accenture GmbH and as an ABAP Software Engineer at Accenture in Guangzhou, PR. China. In these roles, I was responsible for implementing SAP migration projects using the SNP migration tool, training new joiners, and developing ABAP based on different modules. My knowledge of SAP FI is comprehensive and includes consolidation (Group Reporting) as well as knowledge of CO functionalities.

      //     I also have experience in coordinating internal and external resources within a project, identifying and defining test cases for integration and user acceptance tests, driving forward the integration of further modules or third-party systems, and providing 2nd and 3rd level support. My education from Guangdong University of Business Studies in Computer Science and Business Administration from Bayreuth University has provided me with the technical skills and business acumen to excel in this role.

      //     I am passionate about technology and applying new technologies to solve complex problems. My solution-oriented and analytical way of working, combined with my excellent German and English language skills, makes me an ideal candidate for this position.

      //     Thank you for your time and consideration`,
      //     // outputs: [],
      //     // Icon: RefreshIcon,
      //     // color: "",
      //   },
    },
  ],
};

export default obj;
