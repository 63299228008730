import { ClipboardListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Motivationsschreiben für ein Studienplatz erstellen",
  desc: "Erstelle ein professionelles Motivationsschreiben für einen Studienplatz.",
  category: "University",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/university/university1",
  api: "/ai/university/university1",
  apiCv: "/ai/upload-cv-motivationletter1",
  currentSchema: "university1",

  fromColor: "green-500",
  toColor: "blue-500",

  output: {
    title: "Motivationsschreiben",
    desc: "Dein persönliches Motivationsschreiben für deinen Studienplatz. Hinweis: Wir übernehmen keine Haftung für die Korrektheit, deswegen empfehlen wir den generierten Text ausführlich zu prüfen.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Motivationschreiben für ein Studienplatz",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank!",
      // n: 1,
      prompts: [
        {
          title: "Titel des Studienganges",
          attr: "positionTitle",
          value: "",
          placeholder: "Maschinenbau (MSc)",
          label: "Beispiel: Maschinenbau (MSc)",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Maschinenbau (MSc)",
        },
        {
          title: "Name der Universität",
          attr: "universityName",
          value: "",
          placeholder: "FAU",
          label: "Beispiel: TUM, FAU,",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "FAU",
        },

        {
          title: "Kurs Beschreibung",
          attr: "programDescription",
          value: "",
          placeholder: "Bitte Details über das Kursprogramm eingeben...",
          //label: "Beispiel: Bosch GmbH, Siemens AG",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example:
            " Ziel des Masterstudiengangs ist es, Ihnen vertiefte ingenieurwissenschaftliche Methoden und Kenntnisse sowie forschungsqualifizierende wissenschaftliche Arbeitsweisen zu vermitteln. Im Masterstudium stehen folgende Studienrichtungen zur Auswahl:",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "namePerson",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },
        {
          title: "Wieso diese Universität (Optional)",
          attr: "whyThisUniversity",
          value: "",
          placeholder:
            "Erläutere, warum du dich für diese bestimmte Universität interessierst? Du kannst z.B. den Ruf, die Fakultät, den Lehrplan und den Standort erwähnen",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Diese Universität hat ein sehr gutes Ranking und ist bekannt für die gute Ausbildung.",
        },
        {
          title: "Wieso dieser Studiengang/Kurs (Optional)",
          attr: "whyThisCourse",
          value: "",
          placeholder:
            "Erläutere, warum du dich für diese Kurs bewerben möchtest? Du kannst z.B. darauf eingehen, wie der Kurs mit Ihren beruflichen Zielen oder persönlichen Interessen übereinstimmt?",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe eine große Leidenschaft für Technik und Mathematik",
        },
        {
          title: "Deine persönlichen Interessen (Optional)",
          attr: "myInterest",
          value: "",
          placeholder: "Erzähle über deine persönlichen Interessen?",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "",
        },

        {
          title: "Deine Qualifikation (Optional)",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deine bisherige Qualifikation.",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "Bachelor Maschinenbau",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung.`,
          label: "Optional",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: `6 Monate Praktikum bei Tecosim im Bereich Simulation`,
        },
        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "CAD, SolidWorks, Konstruktion, Programmierung ",
        },
        {
          title: "Deine Ziele (Optional)",
          attr: "myGoals",
          value: "",
          placeholder:
            "Erläutern deine beruflichen Ziele und wie das Studium an dieser Universität dir helfen wird, sie zu erreichen. Dies zeigt, dass du einen klaren Plan für deine Zukunft hast.",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "Ich möchte nach meinem Master eine Professur anstreben. ",
        },

        {
          title: "Sprache",
          attr: "language",
          value: "",
          //label: "",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Frau Müller,

        mein Name ist [Ihr Name] und es ist mir eine große Freude, mich für den Masterstudiengang Maschinenbau an der renommierten Friedrich-Alexander-Universität Erlangen-Nürnberg zu bewerben. Dieser Kurs spiegelt eindeutig meine Leidenschaft für Technik und Mathematik wider, die mich dazu bewegt hat, tief in diese Bereiche einzutauchen.
        
        Die FAU ist für ihr erstklassiges global geranktes Bildungsniveau bekannt und hat großes Ansehen für die effektive Vermittlung ingenieurwissenschaftlicher Methoden und Kenntnisse. Solche Hochschulangebote, zusammen mit einer bemerkenswerten Fakultät und Standortvorteilen, machen diese Institution zu meiner ersten Wahl.
        
        Ich habe meinen Bachelor in Maschinenbau abgeschlossen und konnte bereits wertvolle praktische Erfahrungen sammeln, als ich ein halbjähriges Praktikum bei Tecosim absolviert habe. Hier konnte ich meine Fähigkeiten in CAD, SolidWorks, Konstruktion und Programmierung weiter ausbauen. Dieses breite Spektrum an Kenntnissen und Erfahrungen macht mich zu einem starken Kandidaten für den Masterstudiengang.
        
        Es ist mein ehrgeiziges Ziel, nach Abschluss des Masterstudiums eine Professur anzustreben. Ich bin fest davon überzeugt, dass mir die FAU durch ihre akademische Exzellenz und fundierte Forschungsarbeit auf diesem Weg hilfreich sein wird.
        
        Vielen Dank, dass Sie sich Zeit genommen haben, meine Bewerbung zu lesen. Ich freue mich auf eine positive Berücksichtigung und darauf, Ihre Bildungseinrichtung mit meinem Engagement und meiner Begeisterung zu bereichern. 
        
        Mit freundlichen Grüßen,
        [Ihr voller Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
      // },
      // {
      //   title: "Motivation Letter Basic",
      //   desc: "Please fill the blanks...",
      //   // n: 1,
      //   prompts: [
      //     {
      //       title: "Job Title",
      //       attr: "title",
      //       value: "",
      //       placeholder: "Junior Accountant",
      //       label: "Examples: Accountant, Developer, Dentist, Receptionist",
      //       // type: "textarea",
      //       maxLength: 40,
      //       // max: 100,
      //       min: 10,
      //       required: true,
      //       error: "",
      //       example: "Junior Accountant",
      //     },
      //     {
      //       title: "Company",
      //       attr: "company",
      //       value: "",
      //       placeholder: "Company Ptd Ltd",
      //       label: "Examples: Tax Point Inc., Moshi Pty Ltd, Studium",
      //       // type: "textarea",
      //       maxLength: 40,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "Smith and Co",
      //     },
      //     {
      //       title: "Job Description",
      //       attr: "description",
      //       value: "",
      //       placeholder:
      //         "We are looking for a Software Developer to build and implement functional programs.",
      //       // label: "Examples: 40k, $20,000, $50 per hour",
      //       type: "textarea",
      //       maxLength: 1500,
      //       // max: 100,
      //       min: 3,
      //       required: true,
      //       error: "",
      //       example:
      //         "We are looking for a Software Developer to build and implement functional programs.",
      //     },
      //     {
      //       title: "Your Skills",
      //       attr: "skills",
      //       value: "",
      //       placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //       // label:
      //       //   "Examples: Adrian, adrian@example.com, Call Adrian on 041021031",
      //       type: "textarea",
      //       maxLength: 600,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //     },
      //   ],
      //   example: {
      //     output: `Dear Hiring Manager,

      //     I am writing to apply for the position of Senior Inhouse Consultant SAP FI at BestSecret Group. With my extensive experience in SAP FI consulting, solution design, and hands-on customizing, I am confident that I am the ideal candidate for this role.

      //     My experience includes working as an SAP Consultant for data migration at Accenture GmbH and as an ABAP Software Engineer at Accenture in Guangzhou, PR. China. In these roles, I was responsible for implementing SAP migration projects using the SNP migration tool, training new joiners, and developing ABAP based on different modules. My knowledge of SAP FI is comprehensive and includes consolidation (Group Reporting) as well as knowledge of CO functionalities.

      //     I also have experience in coordinating internal and external resources within a project, identifying and defining test cases for integration and user acceptance tests, driving forward the integration of further modules or third-party systems, and providing 2nd and 3rd level support. My education from Guangdong University of Business Studies in Computer Science and Business Administration from Bayreuth University has provided me with the technical skills and business acumen to excel in this role.

      //     I am passionate about technology and applying new technologies to solve complex problems. My solution-oriented and analytical way of working, combined with my excellent German and English language skills, makes me an ideal candidate for this position.

      //     Thank you for your time and consideration`,
      //     // outputs: [],
      //     // Icon: RefreshIcon,
      //     // color: "",
      //   },
    },
  ],
};

export default obj;
