import { ClipboardListIcon } from "@heroicons/react/solid";

const obj = {
  title: "Anschreiben für ein Praktikum erstellen",
  desc: "Erstellen Sie ein professionelles Anschreiben für eine Praktikumsstelle.",
  category: "Internship",
  Icon: ClipboardListIcon,
  // tags: [],
  permissions: ["user"],

  to: "/ai/internship/internship1",
  api: "/ai/internship/internship1",
  apiCv: "/ai/upload-cv-motivationletter1",
  currentSchema: "internship1",

  fromColor: "yellow-400",
  toColor: "yellow-600",

  output: {
    title: "Dein persönliches Anschreiben.",
    desc: "Beispiel für ein Anschreiben für ein Praktikum. Hinweis: Wir übernehmen keine Haftung für die Korrektheit, deswegen empfehlen wir den generierten Text ausführlich zu prüfen.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "AI Anschreiben-Generator für ein Praktikum",
      desc: "Bitte freie Felder auszufüllen. Je mehr Details du angibst, desto individueller und überzeugender wird dein Bewerbungsschreiben. Vielen Dank!",
      // n: 1,
      prompts: [
        {
          title: "Titel der Praktikumsstelle",
          attr: "positionTitle",
          value: "",
          placeholder: "Praktikum Mechatroniker",
          label: "Beispiel: Praktikum Online-Marketing, Praktikum Verkäufer",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "Praktikum/Praxissemester Engineering Mechanics",
        },
        {
          title: "Name der Firma (Optional)",
          attr: "companyName",
          value: "",
          placeholder: "Bitte Firmen Name eingeben...",
          label: "Beispiel: Bosch GmbH, Siemens AG",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "Bosch GmbH",
        },
        {
          title: "Name des Ansprechpartners (Optional)",
          attr: "nameHiringManager",
          value: "",
          placeholder: " Bitte Name eingeben...",
          label: "",
          // type: "textarea",
          maxLength: 150,
          // max: 100,
          // min: 3,
          // required: false,
          error: "",
          example: "Frau Müller",
        },
        {
          title: "Quelle der Stellenanzeige (Optional)",
          attr: "sourceListing",
          value: "",
          placeholder: "Wo hast du die Stellenanzeige gefunden?",
          label: "Beispiel: Stepstone, Xing",

          maxLength: 150,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example: "indeed.com",
        },
        {
          title: "Stellenbeschreibung",
          attr: "internshipDescription",
          value: "",
          placeholder:
            "Hier kannst du die die komplette Stellenanzeige hineinkopieren. " +
            "Zum Beispiel: Was wir erwarten: Mit einem Schulpraktikum hast du bei uns die Möglichkeit Einblicke in den Beruf...",
          // label: "Examples: 40k, $20,000, $50 per hour",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          min: 50,
          required: true,
          error: "",
          example: `IHRE AUFGABEN

            Versuchsdurchführungen und Auswertungen an Prüfständen
            Messungen und Tests diverser Materialeigenschaften
            Unterstützung bei unterschiedlichen Entwicklungsprojekten
            Montageunterstützung an verschiedenen Prüfständen
            Unterstützung der Ingenieure bei Konstruktionstätigkeiten
            Dokumentation sämtlicher Versuchs-/Messergebnisse
            IHR PROFIL
            
            Technisches Studium (Universität/ FH) der Ingenieurswissenschaften
            Analytische Fähigkeiten sowie selbstständiges und strukturiertes Arbeiten
            Ausgeprägte Team-/Kommunikationsfähigkeit sowie Eigeninitiative und Flexibilität
            SAP- und CAD- Kenntnisse (Solid Edge) von Vorteil
            Gute Englischkenntnisse
            Mögliche Starttermine: ab Wintersemester 2023 `,
        },

        {
          title: "Dein Status (Optional)",
          attr: "myStatus",
          value: "",
          placeholder:
            "Erzähle kurz über deinen beruflichen Status, bist du Student, Schüler, Absolvent ect...",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich bin im 3 Semester meines Bachelorstudiums an der FAU Erlangen/Nuernberg",
        },
        {
          title: "Dein Interesse (Optional)",
          attr: "myInterest",
          value: "",
          placeholder:
            "Erzähle kurz darüber, wieso du dich für diese Stelle bewerben möchtest? Worin liegt deine Hauptmotivation? ",
          label: "",
          type: "textarea",
          maxLength: 2500,
          // max: 100,
          // min: 3,
          required: false,
          error: "",
          example:
            "Ich habe eine große Begeisterung für Elektronik und Mechanik. ",
        },

        {
          title: "Deine Berufserfahrung (Optional)",
          attr: "myExperience",
          value: "",
          placeholder: `Erzähle über deine relevante Berufserfahrung,`,
          label: "Optional",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: `6 Monate Praktikum bei Tecosim im Bereich Simulation`,
        },
        {
          title: "Deine Qualifikation",
          attr: "myEducation",
          value: "",
          placeholder: "Erzähle kurz über deinen Werdegang...",
          // label:
          //   "Examples: Adrian, adrian@example.com, Call Adrian on 041021031",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: true,
          error: "",
          example: "Bachelor in Informatik",
        },
        {
          title: "Deine Fähigkeiten (Optional)",
          attr: "mySkills",
          value: "",
          placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
          label: "",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          // required: true,
          error: "",
          example: "CAD, SolidWorks, Konstruktion, Programmierung ",
        },
        {
          title: "Deine Projekte (Optional)",
          attr: "myProjects",
          value: "",
          placeholder:
            "Erzähle über besondere Projekte, welche du in deiner Freizeit erfolgreich gemeisterst hast.",
          label: "Ich habe mit einem Freund ein Quadcopter gebaut.",
          type: "textarea",
          maxLength: 600,
          // max: 100,
          // min: 1,
          required: false,
          error: "",
          example: "Ich habe mit einem Freund ein Quadcopter gebaut.",
        },
        {
          title: "Sprache",
          attr: "language",
          value: "",
          //label: "DE: Deutsch, EN: Englisch",
          type: "dropdown",
          options: [
            { value: "DE", label: "Deutsch" },
            {
              value: "EN",
              label: "English",
            },
          ],
          // maxLength: 40,
          // max: 100,
          // min: 10,
          required: true,
          error: "",
          example: "DE",
        },
      ],
      example: {
        output: `Sehr geehrte Frau Müller, 

        hiermit bewerbe ich mich um die auf indeed.com ausgeschriebene Stelle als Praktikum/Praxissemester Technische Mechanik bei der Brückner Maschinenbau GmbH & Co. KG, wie auf indeed.com ausgeschrieben. Ich befinde mich derzeit im dritten Semester meines Bachelorstudiums der Ingenieurwissenschaften an der Hochschule Hof. 
        
        An dieser Stelle bin ich besonders interessiert, da ich hier meine CAD-Kenntnisse, sowie meine Kenntnisse in der Konstruktion und Programmierung einsetzen kann. Ich habe bereits 6 Monate Erfahrung im Bereich der Simulation bei Tecosim gesammelt und bin überzeugt, dass mich diese Erfahrung ausreichend auf die Aufgabe vorbereitet hat.
        
        Neben meinen technischen Fähigkeiten verfüge ich auch über ausgeprägte analytische Fähigkeiten und ausgezeichnete Kommunikations- und Teamfähigkeiten. Ich bin hoch motiviert und proaktiv und habe eine große Leidenschaft für technische Mechanik. Als Beweis dafür habe ich ein Projekt im Bereich der Konstruktion abgeschlossen und arbeite derzeit an einem Projekt im Bereich der Robotik. 
        
        Ich bin überzeugt, dass meine Ausbildung, meine Fähigkeiten und meine bisherigen Erfahrungen mich zu einem idealen Kandidaten für diese Stelle machen. Ich freue mich darauf, zum Team beizutragen und meine Kenntnisse in diesem Bereich weiter auszubauen. 
        
        Vielen Dank, dass Sie meine Bewerbung berücksichtigt haben. 
        
        Mit freundlichen Grüßen, 
        [Ihr Name]`,
        // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
      // },
      // {
      //   title: "Motivation Letter Basic",
      //   desc: "Please fill the blanks...",
      //   // n: 1,
      //   prompts: [
      //     {
      //       title: "Job Title",
      //       attr: "title",
      //       value: "",
      //       placeholder: "Junior Accountant",
      //       label: "Examples: Accountant, Developer, Dentist, Receptionist",
      //       // type: "textarea",
      //       maxLength: 40,
      //       // max: 100,
      //       min: 10,
      //       required: true,
      //       error: "",
      //       example: "Junior Accountant",
      //     },
      //     {
      //       title: "Company",
      //       attr: "company",
      //       value: "",
      //       placeholder: "Company Ptd Ltd",
      //       label: "Examples: Tax Point Inc., Moshi Pty Ltd, Studium",
      //       // type: "textarea",
      //       maxLength: 40,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "Smith and Co",
      //     },
      //     {
      //       title: "Job Description",
      //       attr: "description",
      //       value: "",
      //       placeholder:
      //         "We are looking for a Software Developer to build and implement functional programs.",
      //       // label: "Examples: 40k, $20,000, $50 per hour",
      //       type: "textarea",
      //       maxLength: 1500,
      //       // max: 100,
      //       min: 3,
      //       required: true,
      //       error: "",
      //       example:
      //         "We are looking for a Software Developer to build and implement functional programs.",
      //     },
      //     {
      //       title: "Your Skills",
      //       attr: "skills",
      //       value: "",
      //       placeholder: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //       // label:
      //       //   "Examples: Adrian, adrian@example.com, Call Adrian on 041021031",
      //       type: "textarea",
      //       maxLength: 600,
      //       // max: 100,
      //       // min: 1,
      //       // required: true,
      //       error: "",
      //       example: "HTML/CSS3, JavaScript, NodeJS, PHP, GitLab",
      //     },
      //   ],
      //   example: {
      //     output: `Dear Hiring Manager,

      //     I am writing to apply for the position of Senior Inhouse Consultant SAP FI at BestSecret Group. With my extensive experience in SAP FI consulting, solution design, and hands-on customizing, I am confident that I am the ideal candidate for this role.

      //     My experience includes working as an SAP Consultant for data migration at Accenture GmbH and as an ABAP Software Engineer at Accenture in Guangzhou, PR. China. In these roles, I was responsible for implementing SAP migration projects using the SNP migration tool, training new joiners, and developing ABAP based on different modules. My knowledge of SAP FI is comprehensive and includes consolidation (Group Reporting) as well as knowledge of CO functionalities.

      //     I also have experience in coordinating internal and external resources within a project, identifying and defining test cases for integration and user acceptance tests, driving forward the integration of further modules or third-party systems, and providing 2nd and 3rd level support. My education from Guangdong University of Business Studies in Computer Science and Business Administration from Bayreuth University has provided me with the technical skills and business acumen to excel in this role.

      //     I am passionate about technology and applying new technologies to solve complex problems. My solution-oriented and analytical way of working, combined with my excellent German and English language skills, makes me an ideal candidate for this position.

      //     Thank you for your time and consideration`,
      //     // outputs: [],
      //     // Icon: RefreshIcon,
      //     // color: "",
      //   },
    },
  ],
};

export default obj;
