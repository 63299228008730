import Loader from "./Loader";
// import Typist from 'react-typist';
import { CheckIcon } from "@heroicons/react/solid";
import {
  DuplicateIcon,
  ExclamationCircleIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { TypeAnimation } from "react-type-animation";
import { Document, Packer, Paragraph, TextRun } from "docx";

import CodeEditor from "@uiw/react-textarea-code-editor";
import { keyframes } from "styled-components";
import Modal from "./Modal";

export const Output = inject("store")(
  observer(
    ({
      store,
      title,
      desc,
      Icon,
      output,
      code,
      language,
      outputs,
      loading,
      children,
      fromColor,
      toColor,
      outputsColor,
      OutputsIcon,
    }) => {
      // Method to check if the user is a premium user
      const isUserPremium = () => {
        return (
          store.profile.plan === "trial" || store.profile.plan === "premium"
        );
      };

      return (
        <div className="relative mb-12">
          <div
            className={`absolute inset-0 bg-gradient-to-r from-${
              fromColor ? fromColor : "green-400"
            } to-${
              toColor ? toColor : "blue-500"
            } shadow-lg transform md:skew-y-0 md:-rotate-3 md:rounded-3xl -mt-1 md:mt-0`}
          ></div>
          <div className=" align-bottom bg-white md:rounded-3xl text-left  shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl">
            <div className=" px-6 py-6">
              <div className="sm:flex sm:items-start">
                {loading ? (
                  <>
                    <div className="px-1">
                      <Loader active={loading} className="w-10 h-10" />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${
                        output ? "green" : "gray"
                      }-300 sm:mx-0 sm:h-10 sm:w-10 bg-gradient-to-r from-${
                        fromColor ? fromColor : "green-400"
                      } to-${toColor ? toColor : "blue-500"}`}
                    >
                      {Icon ? (
                        <Icon
                          className={`h-6 w-6 text-white`}
                          aria-hidden="true"
                        />
                      ) : null}
                    </div>
                  </>
                )}

                <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </div>
                  <p className="text-sm text-gray-500">{desc}</p>
                </div>
              </div>
              {code ? null : output ? (
                <div className="whitespace-pre-wrap min-w-full text-gray-800 h-auto text-lg divide-y px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {isUserPremium() ? (
                    // For premium users, use TypeAnimation
                    <TypeAnimation
                      speed={100}
                      typing={2}
                      cursor={true}
                      sequence={[output]}
                      wrapper="p"
                    />
                  ) : (
                    // For non-premium users, render non-selectable text
                    <NonSelectableText>
                      <TypeAnimation
                        speed={100}
                        typing={2}
                        cursor={true}
                        sequence={[output]}
                        wrapper="p"
                      />
                    </NonSelectableText>
                  )}
                </div>
              ) : null}

              {output && outputs && outputs.length ? (
                <div className="divide-y divide-dashed divide-gray-300">
                  {" "}
                  <div></div>
                  <div></div>
                </div>
              ) : null}

              {outputs && outputs.length ? (
                <Outputs
                  outputs={outputs}
                  outputsColor={outputsColor}
                  OutputsIcon={OutputsIcon}
                />
              ) : null}

              {code && code.length ? (
                <CodeEditor
                  style={{
                    fontFamily: "JetBrains Mono",
                    fontSize: "1rem",
                  }}
                  padding={30}
                  language={language}
                  value={code}
                />
              ) : null}
              <QuickTools outputs={outputs} output={output} code={code} />
            </div>
          </div>
        </div>
      );
    }
  )
);

const NonSelectableText = styled.p`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

export const QuickTools = inject("store")(
  observer(({ store, output, outputs, code }) => {
    // Method to check if the user is a premium user
    const isUserPremium = () => {
      return store.profile.plan === "trial" || store.profile.plan === "premium";
    };

    const handleCopyToClipboard = () => {
      if (!isUserPremium()) {
        // Show the premium feature modal
        store.setShowCopyToClipboardModal(true); // Update this line
        return;
      }
      store.copyToClipboard(output || code || outputs);
    };

    const downloadDocxFromServer = async (text) => {
      if (!isUserPremium()) {
        // Show the premium feature modal
        store.setShowCopyToClipboardModal(true); // Update this line
        return;
      }

      try {
        const response = await store.api.post(
          "/generate-docx",
          {
            text: text,
          },
          {
            responseType: "arraybuffer", // Add this line
          }
        );

        if (response) {
          const headers = response.headers;

          // Check for expected headers
          const hasCorrectContentType =
            headers["content-type"] &&
            headers["content-type"].includes(
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            );

          if (!hasCorrectContentType) {
            console.error("Received unexpected headers from server:", headers);
            return; // Stop execution if headers are not as expected
          }

          if (response && response.data) {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = "AnschreibenAI.docx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);
          }
        }
      } catch (err) {
        console.log(err);
        if (err?.response?.data?.message) {
          console.error(err?.response?.data?.message);
        }
      }
    };

    return (
      <>
        {output || code || (outputs && outputs.length) ? (
          <div className="flex">
            <Shortcut
              className="p-1 mr-2 rounded-lg cursor-pointer bg-green-200 text-green-700 hover:bg-green-400 hover:text-green-200 relative group flex flex-col items-center group text-gray-300"
              onClick={handleCopyToClipboard}
            >
              <DuplicateIcon className="w-8 h-8" />

              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Text in die Zwischenablage kopieren
                </span>
              </Tooltip>
            </Shortcut>

            {/* Button to download the DOCX */}
            {output ? (
              <Shortcut
                className="p-1  rounded-lg cursor-pointer bg-blue-200 text-blue-700 hover:bg-blue-400 hover:text-blue-200 relative group flex flex-col items-center group text-gray-300"
                onClick={() => downloadDocxFromServer(output)}
              >
                <div className="flex">
                  {/* <span>Download</span> */}
                  <DownloadIcon className="w-8 h-8" />
                </div>
                <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                  <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                    Als Word (.docx) speichern
                  </span>
                </Tooltip>
              </Shortcut>
            ) : null}

            <div className="flex-1"></div>
            {/* <Shortcut
              className="p-1 rounded-lg cursor-pointer bg-red-200 text-red-700 hover:bg-red-400 hover:text-red-200 relative group flex flex-col items-center group text-gray-300"
              onClick={() => store.reportToFeedback(output || code || outputs)}
            >
              <ExclamationCircleIcon className="w-8 h-8" />
              <Tooltip className="absolute bottom-2 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-3 text-sm leading-none text-gray-800 bg-white bg-opacity-25 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                  Problem mit der Ausgabe berichten
                </span>
              </Tooltip>
            </Shortcut> */}
          </div>
        ) : null}
      </>
    );
  })
);

const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;

function Outputs({ outputs, outputsColor, OutputsIcon }) {
  return (
    <div className="whitespace-pre-wrap min-w-full py-4 text-gray-800 h-auto text-lg divide-y">
      {/* <Typist
		stdTypingDelay={0}
		avgTypingDelay={7}
		className="divide-y"
		cursor={{
			show: false,
			blink: false,
			element: '|',
			hideWhenDone: true,
			hideWhenDoneDelay: 250,
		}}
		> */}

      {outputs.map((output, index) => (
        <div className="py-2 flex items-start" key={index}>
          <div
            className={`mr-4 flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
              outputsColor ? outputsColor : "green"
            }-200 text-${outputsColor ? outputsColor : "green"}-600`}
          >
            {OutputsIcon === false ? (
              `${index + 1}`
            ) : OutputsIcon ? (
              <OutputsIcon
                className={`h-6 w-6 text-${
                  outputsColor ? outputsColor : "green"
                }-600`}
                aria-hidden="true"
              />
            ) : (
              <CheckIcon
                className={`h-6 w-6 text-${
                  outputsColor ? outputsColor : "green"
                }-600`}
                aria-hidden="true"
              />
            )}
          </div>
          <TypeAnimation
            speed={99}
            cursor={true}
            sequence={[output]}
            wrapper="p"
          />
        </div>
      ))}

      {/* </Typist>  */}
    </div>
  );
}

function replacePlaceholderWithText(templateBuffer, placeholder, text) {
  const zip = new docx.Zip(templateBuffer);
  const doc = new Document(zip);

  // Process each paragraph in the document
  for (let para of doc.sections[0].children) {
    if (para instanceof Paragraph) {
      for (let child of para.children) {
        if (child instanceof TextRun) {
          child.text = child.text.replace(placeholder, text);
        }
      }
    }
  }

  return Packer.toBuffer(doc);
}

export default Output;
